import React from "react"

import SEO from "../components/seo"
import BaseLayout from "../components/layout/baseLayout"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ColumnLayout from "../components/layout/ColumnLayout"
import JobsList from "../components/jobs/JobsList"

const IndexPage = () => {
  const { t, i18n } = useTranslation()
  const { language } = i18n

  return (
    <>
      <SEO title={t("Jobs")} isHome={false} />
      <BaseLayout>
        <div className="mb-14">
          <ColumnLayout
            title={t("Jobs-Title")}
            description={t("Jobs-Description")}
          >
            <div className="mx-auto xl:w-2/3">
              <JobsList />
            </div>
          </ColumnLayout>
        </div>
      </BaseLayout>
    </>
  )
}

export default IndexPage
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
