import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { langFileFilter } from "../../util/langUtils"
import JobCard from "./JobCard"

const JobsList = () => {
  const { i18n } = useTranslation()
  const { language } = i18n

  const data = useStaticQuery(graphql`
    query {
      jobs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/markdown-pages/" } }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              slug
              title
              language
              employment
              location
              monthlysalary
              salary
              startdate
              techstack
              apply
              summary
              date
              action
            }
            fileAbsolutePath
          }
        }
      }
    }
  `)
  const jobs = langFileFilter(data.jobs.edges, language).filter(job =>
    job.node.fileAbsolutePath.includes("job-")
  )

  return (
    <div className="space-y-8">
      {jobs.map(
        ({
          node: {
            frontmatter: {
              slug,
              title,
              language,
              employment,
              location,
              monthlysalary,
              salary,
              startdate,
              techstack,
              apply,
              summary,
              date,
              action,
            },
          },
        }) => (
          <div className="">
            <JobCard
              slug={slug}
              title={title}
              language={language}
              employment={employment}
              location={location}
              monthlysalary={monthlysalary}
              salary={salary}
              startdate={startdate}
              techstack={techstack}
              apply={apply}
              summary={summary}
              date={date}
              action={action}
            />
          </div>
        )
      )}
    </div>
  )
}

export default JobsList
