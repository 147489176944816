import {
  BriefcaseIcon,
  LocationMarkerIcon,
  CurrencyEuroIcon,
  CalendarIcon,
  CubeTransparentIcon,
  ExternalLinkIcon,
} from "@heroicons/react/outline"
import { Link } from "gatsby"
import React from "react"
import { Trans, useTranslation } from "react-i18next"

interface IJobCardProps {
  slug: string
  title: string
  language: string
  employment: string
  location: string
  monthlysalary: string
  salary: string
  startdate: string
  techstack: string
  apply: string
  summary: string
  date: string
  action: string
}

const JobCard = ({
  slug,
  title,
  language,
  employment,
  location,
  monthlysalary,
  salary,
  startdate,
  techstack,
  apply,
  summary,
  date,
  action,
}: IJobCardProps) => {
  const { i18n } = useTranslation()
  return (
    <div className="shadow-md p-6 rounded-lg border border-slate-200 bg-white">
      <Link
        to={slug}
        className="text-primary hover:text-primary-550 font-bold text-lg leading-6"
      >
        {title}
      </Link>
      <div className="mt-0.5 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6 mb-6 pt-2 sm:pt-2 space-y-2 md:space-y-0">
        <div className="flex items-center text-sm text-gray-500">
          <BriefcaseIcon
            className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          {employment}
        </div>
        {location && (
          <div className="flex items-center text-sm text-gray-500">
            <LocationMarkerIcon
              className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {location}
          </div>
        )}
        {salary && (
          <div className="flex items-center text-sm text-gray-500">
            <CurrencyEuroIcon
              className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {salary}
          </div>
        )}
        {startdate && (
          <div className="flex items-center text-sm text-gray-500">
            <CalendarIcon
              className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {startdate}
          </div>
        )}
        {/* {techstack && (
          <div className="group flex items-center text-sm text-gray-500">
            <CubeTransparentIcon
              className="shrink-0 mr-1.5 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <a
              href={techstack}
              target={"_blank"}
              rel="noopener noreferrer"
              className="cursor-pointer"
            >
              <span>Tech Stack</span>
              <ExternalLinkIcon
                className="inline relative ml-1.5 -mt-1 h-4 w-4 "
                aria-hidden="true"
              />
            </a>
          </div>
        )} */}
      </div>
      <div className="">{summary}</div>
      {slug && (
        <div className="inline-block mt-6 flex justify-between">
          <Link to={slug} className=" justify-center btn-solid">
            {action}
          </Link>
          {/* <div className="text-slate-500 mt-auto">
            {new Date(date).toLocaleDateString(i18n.language)}
          </div> */}
        </div>
      )}
      {!slug && (
        <div className="mt-6 flex justify-between">
          <a href={apply} className="inline-block justify-center btn-solid">
            {action}
          </a>
          {/* <div className="text-slate-500 mt-auto">
            {new Date(date).toLocaleDateString(i18n.language)}
          </div> */}
        </div>
      )}
    </div>
  )
}

export default JobCard
